import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { StorageKeys } from './shared/enums/StorageKeys.enum';
import { SiloService } from './shared/services/silo-service';
import { StorageService } from './shared/services/storage.service';
import { ToastService } from './shared/services/toast.service';
import { HostRoute } from './site-manager/enums/Host.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('closed', style({ margin: '-100px 0 0 0' })),
      state('open', style({ margin: '0' })),
      transition('closed <=> open', animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class AppComponent implements OnInit {

  sessionVerifiedSource$: boolean;
  initComplete$: boolean;

  // We need to wait for the session to be initialzed. This prevents
  // the page from rendering until we know we have a bearer token
  sessionInitialized: boolean;

  constructor(private toast: ToastService,
    private loader: LoaderService,
    private authService: AuthenticationService,
    private storageService: StorageService,
    private siloService: SiloService) { }

  ngOnInit(): void {

    this.authorizeSession();
    
    // Listen for the users permissions
    this.authService.sessionVerified$.pipe(
      take(1),
      tap((response) => {
        this.sessionVerifiedSource$ = response;
        this.initComplete$ = true;
        this.sessionInitialized = true;
      })
    ).subscribe();
  }

  get loading() {
    return this.loader.isLoading;
  }

  /**
  * Get the token from the url and validate it
  */
  authorizeSession() {
    const token = this.getToken();
    if (token == null) {
      this.toast.invalidSession('No token supplied');
      return;
    }

    this.authService.validateToken(token);
    this.setAppRoute();
  }

  setAppRoute() {
    const route: HostRoute = new URL(window.location.href).searchParams.get('route') as HostRoute ?? null;
    const selectedSiteId = new URL(window.location.href).searchParams.get('selectedSite') ?? null;
    if(selectedSiteId) {
      this.siloService.selectedSiteId = selectedSiteId;
      this.siloService.selectSite(selectedSiteId);
    }
    sessionStorage.setItem(StorageKeys.HOST_APP, route);
  }

  getToken() {  
    return new URL(window.location.href).searchParams.get('token') || this.storageService.get(StorageKeys.BEARER_TOKEN);
  }
}
